
import AppContent from "./AppContent";

function App() {
  return (
    <AppContent />
  );
}

export default App;
